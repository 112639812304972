import React from 'react';
import ReactDOM from 'react-dom/client';
import TelegramApp from './TelegramApp';
import { Provider } from 'react-redux'
import store from "./DataProvider/store";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {getTheme} from "./theme";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={getTheme(true)}>
            <CssBaseline />
            <Provider store={store}>
                <TelegramApp />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
