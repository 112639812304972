import {createTheme, Theme} from '@mui/material/styles';
import {lightGreen, red} from '@mui/material/colors';
import {PaletteOptions} from "@mui/material/styles/createPalette";

const lightPalette: PaletteOptions = {
    mode: 'light',
    primary: {
        dark: "#7D187B",
        main: "#AF4CAB",
        light: "#E37CDD",
    },
    secondary: {
        dark: "#087F23",
        main: "#4CAF50",
        light: "#80E27E",
    },

    error: {
        main: red.A400,
    },
    background: {
        paper: lightGreen["100"],
        default: '#ffffff',
    },
    text: {
        primary: 'rgba(0, 0, 0, .8)',
    },
};

const darkPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
        dark: "#7D187B",
        main: "#AF4CAB",
        light: "#E37CDD",
    },
    secondary: {
        dark: "#087F23",
        main: "#4CAF50",
        light: "#80E27E",
    },
    error: {
        main: red.A400,
    },
    background: {
        paper: '#1a1a1a',
        default: '#0a0a0a',
    },

    text: {
        primary: 'rgba(255, 255, 255, .8)',
    },
};
export function getTheme(darkMode: boolean = false): Theme {
    return createTheme({
        palette: darkMode ? darkPalette : lightPalette,
        shape: {
//        borderRadius: 16,
        },
        typography: {
            fontFamily: [
                'Roboto Flex',
                'sans-serif',
            ].join(','),
            fontSize: 14,
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
//                    borderRadius: 18
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        background: 'none',
                        border: 'none',
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        height: 16
                    }
                },
            }
        }
    });
}
