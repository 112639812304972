import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {RootState} from "./store";
import {setCredentials} from "../Feature/Auth/authSlice";

const baseApiUrl = window.runtime.REACT_APP_BASE_API_URL;

export const baseQuery = fetchBaseQuery({
    baseUrl: baseApiUrl,
});

export const baseQueryWithAuthHeader = fetchBaseQuery({
    baseUrl: baseApiUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

let authRefreshInProcess = false;

export const baseQueryWithAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError, {anonymous?: true, notRefreshTokenIfExpired?: true}
> = async (args, api, extraOptions) => {
    let result;
    if (!extraOptions?.anonymous) {
        result = await baseQueryWithAuthHeader(args, api, extraOptions);
    } else {
        result = await baseQuery(args, api, extraOptions);
    }

    if (
        result.error
        && result.error.status === 401
        && !extraOptions?.notRefreshTokenIfExpired
    ) {
        if (authRefreshInProcess) {
            result = baseQueryWithAuth(args, api, extraOptions);
        } else {
            const token = (api.getState() as RootState).auth.token;

            const refreshResult = await baseQuery({
                url: "/auth/refresh-token",
                method: "post",
                body: {token: token}
            }, api, extraOptions);

            console.log("qqq");
            console.log(refreshResult);

            if (refreshResult.data) {
                const data = refreshResult.data as {token: string};
                api.dispatch(setCredentials(data.token));
                result = await baseQueryWithAuth(args, api, extraOptions);
            } else {
                api.dispatch(setCredentials(undefined));
            }
            authRefreshInProcess = false;
        }
    }
    return result;
};

const baseApi = createApi({
    reducerPath: 'splitApi',
    baseQuery: baseQueryWithAuth,
    endpoints: () => ({}),
})

export default baseApi;
