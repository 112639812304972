import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../DataProvider/store";
import authApi from "./authApi";

const LOCAL_STORAGE_KEY_SUFFIX = 'auth-';

type AuthState = {
    telegramId?: number
    token?: string,
}

const telegramId = window.Telegram.WebApp.initDataUnsafe.user?.id ?? undefined;

const initialState: AuthState = {
    telegramId: telegramId,
    token: telegramId ? (window.localStorage.getItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`) ?? undefined) : undefined,
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<string|undefined>) => {
            state.token = action.payload;
            if (state.token !== undefined) {
                window.localStorage.setItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`, state.token);
            } else {
                window.localStorage.removeItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.telegramAuth.matchFulfilled,
            (state, { payload }) => {
                state.token = payload.token;
                if (state.token !== undefined) {
                    window.localStorage.setItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`, state.token);
                } else {
                    window.localStorage.removeItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`);
                }
            },
        )
    },
})

export default slice
export const { setCredentials } = slice.actions
export const selectAuthToken = (state: RootState) => state.auth.token;