import baseApi from "../../DataProvider/baseApi";

export interface AuthResponse {
    token: string
}

export interface TelegramAuthRequest {
    data: string
}

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        telegramAuth: builder.mutation<AuthResponse, string>({
            extraOptions: {anonymous: true},
            query: (appData) => ({
                url: '/auth/telegram-mini-app',
                method: 'POST',
                body: {data: appData},
            }),
        }),
        refreshToken: builder.mutation<AuthResponse, string>({
            extraOptions: {anonymous: true},
            query: (token) => ({
                url: '/auth/refresh-token',
                method: 'POST',
                body: {token: token},
            }),
        }),
    }),
});

export default api;
export const { useTelegramAuthMutation } = api