import {Avatar, Container, Typography} from "@mui/material";
import Preloader from "../../Component/Preloader";
import React from "react";
import {useGetMyProfileQuery} from "./profileApi";

export default function () {
    const {data, status, error} = useGetMyProfileQuery();
    console.log(error);
    if (!data) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }
    return (
        <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center", flexDirection: "column"  }}>
            <Avatar
                alt={data.username}
                src={data.userpicUrl ?? ""}
                sx={{ width: 56, height: 56 }}
            />
            <Typography variant="h6" sx={{pt: 2}}>Hello, {data.username}</Typography>
        </Container>
    );
}