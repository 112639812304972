import { createApi } from '@reduxjs/toolkit/query/react'
import baseApi, {baseQueryWithAuth} from "../../DataProvider/baseApi";

export interface ProfileResponse {
    username: string,
    status: string,
    userpicUrl: string|null,
}

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMyProfile: builder.query<ProfileResponse, void>({
            query: () => ({
                url: '/profile/me',
                method: 'GET',
            }),
        }),
    }),
});

export default api

export const { useGetMyProfileQuery } = api