import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux"
import authSlice from "../Feature/Auth/authSlice";
import baseApi from "./baseApi";
import profileSlice from "../Feature/Profile/profileSlice";

const store = configureStore({
    reducer: combineReducers({
        [baseApi.reducerPath]: baseApi.reducer,
        auth: authSlice.reducer,
        profile: profileSlice.reducer,
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(baseApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = useDispatch<AppDispatch>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store