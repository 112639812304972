import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type ProfileState = {
    username: string,
    status: string,
    userpicUrl: string|null,
}

const initialState: ProfileState|null = null;

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
})

export default slice